<template>
    <div :tt="clientWidth">
      <el-carousel :interval="5000" arrow="always" class="bananrList" :height="swiperHeight + 'px'">
        <el-carousel-item v-for="(swiper, index) in banner" :key="index" ><!---->
          <!-- <el-image
            :src="swiper.img"
            fit="cover"></el-image> -->
            <img :src="swiper.img" alt="" class="swiper-item-img" >
        </el-carousel-item>
      </el-carousel>
      <!--第一部份-->
<!--      <div class="content_one bgfff">-->
<!--        <h2>您的企业在不同成长阶段是否遇到以下痛点</h2>-->
<!--        <el-row class="box">-->
<!--          <el-col :span="6" v-for="list in oneList" class="info">-->
<!--            <div class="body">-->
<!--              <div class="content">-->
<!--                <h3>{{list.title}}</h3>-->
<!--                <p v-html="list.value"></p>-->
<!--                <span>查看详情<i class="el-icon-right"></i></span>-->
<!--              </div>-->
<!--              <div class="line">-->
<!--                <img :src="list.img" alt="">-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->
      <!--第二部份-->
      <div class="content_one bgf4f4fc pt-20">
        <!--第四部份-->
        <h2>为企业及学员创造的价值</h2>
        <div class="fourBox" v-if="!isMobile">
          <div class="flex">
            <div class="left">
              <div class="info" v-for="list in fourLeftList" >
                <p :class="list.class" style="font-size: 16px">
                  <span class="img"><img src="@/assets/images/rande.png" alt=""> </span>
                  <span v-html="list.value"></span>
                </p>
              </div>
            </div>
            <div class="center">
              <img src="@/assets/images/yunduo.png" alt="" style="width:100%">
            </div>
            <div class="right">
              <div class="info" v-for="list in fourRightList" >
                <p :class="list.class" style="font-size: 16px">
                  <span class="img"><img src="@/assets/images/rande.png" alt=""> </span>
                  <span v-html="list.value"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!----->
        <div class="sixBox" v-else >
          <div class="box inline-block">
              <i class="el-icon-office-building"></i>
              <p>企业面</p>
              <div><img src="@/assets/images/b_line.png" alt="" class="img"></div>
              <div class="color333" v-for="list in fourLeftList">
                <span class="img"><img src="@/assets/images/rande.png" alt=""> </span>
                <span v-html="list.value" class="info"></span>
              </div>
            </div>
            <div class="box inline-block">
              <i class="el-icon-user"></i>
              <p>学员面</p>
              <div><img src="@/assets/images/b_line.png" alt="" class="img"></div>
              <div class="color333" v-for="list in fourRightList">
                <span class="img"><img src="@/assets/images/rande.png" alt=""> </span>
                <span v-html="list.value" class="info"></span>
              </div>
            </div>
        </div>
<!--        <h2>企业组织发展和绩效提升，痛点是什么</h2>-->
        <h2>您的企业在不同成长阶段是否遇到以下痛点</h2>
        <div class="threeBox mb-50">
          <el-row  :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-for="list in threeBoxList" class="info">
              <div class="body">
                <img :src="list.icon" alt="" class="icon">
                <h3 style="border-bottom: #0b0b0b 1px solid; padding-bottom: 15px;">{{list.title}}</h3>
                <p v-html="list.value" style="margin-top: 20px; color: #333333;"></p>
                <el-button type="primary" size="mini" @click="$refs.modalBoxPage.dialogVisible = true" class="btn__" style="background-color: #E6A23C;border-color: #E6A23C;">免费获取解决方案</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!--第五部份-->
      <div class="fiveBox">
        <h3>赋能行业，以智取胜</h3>
        <span class="line"></span>
        <h4 style="font-size: 14px">整合多领域核心资源，打造一站式、全品类、高标准的培训赋能生态</h4>
        <div class="content" v-if="!isMobile">
            <el-carousel :interval="5000" arrow="always" indicator-position="none">
            <el-carousel-item v-for="(list, index) in fiveTwoList" :key="'f' + index">
                <div class="fiveListBox">
                <div class="box" v-for="(item, index1) in list" :key="'s' + index1">
                <!-- <img :src="item.img" alt="" class="fiveImg"> // -->
                <el-image
                  class="fiveImg"
                  :src="item.img"
                  fit="fill"></el-image>
                <h4>{{item.name}}</h4>
                <span v-html="item.info"></span>
                </div>
                </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="content" v-else>
          <el-carousel :interval="5000" arrow="always" indicator-position="none">
            <el-carousel-item v-for="(item, index) in fiveTwoList[0]" :key="'f' + index">
                <div class="fiveListBox">
                <div class="box"  >
                <!-- <img :src="item.img" alt="" class="fiveImg"> // -->
                <el-image
                  class="fiveImg"
                  :src="item.img"
                  fit="fill"></el-image>
                <h4>{{item.name}}</h4>
                <span v-html="item.info"></span>
                </div>
                </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!--第六部份-->
      <div class="sixBox bgf4f4fc">
        <h3>为什么选择我们</h3>
        <span class="t" style="color: #333333;">劳务行业解决方案服务商</span>
        <div class="c1200">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8"  v-for="(item,index) in sixBoxList">
              <div class="box" :key="index">
                <i :class="item.icon"></i>
                <p>{{ item.name }}</p>
                <div><img src="@/assets/images/b_line.png" alt="" class="img"></div>
                <span style="color: #333333;">{{ item.value }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <modalBox ref="modalBoxPage" />
</div>
</template>

<script>
import modalBox from './components/modal.vue';
  export default {
  name: "indexPage",
  components:{modalBox},
  data() {
    return {
      banner:[],
      // oneList:[
      //   {value:"什么都快<br/>可是管理者能力却跟不上", title:'成长期', url:"", img:require('@/assets/images/sLine.png')},
      //   {value:"震动之后有失控风险<br/>需要管理者迅速胜任稳定局面", title:'收缩期', url:"", img:require('@/assets/images/sLine.png')},
      //   {value:"培训了却不管用<br/>成长速度不尽如人意", title:'成熟期', url:"", img:require('@/assets/images/sLine.png')},
      //   {value:"管理者对即将来临的业务<br/>团队变化准备不足<br/>恐难以快速适应", title:'转型期', url:""},
      // ],
      threeBoxList:[
        {value:"学习体系搭建<br/>帮助企业量身打造人才培养解决方案", title:"表面缺人才，实际缺机制", icon:require("@/assets/images/icon1.png")},
        {value:"帮助企业统一思想、理清分工、压实责任<br/>推进关键工作有序高效开展", title:"表面缺标准，实际缺执行", icon:require("@/assets/images/icon2.png")},
        {value:"推动绩效提升，促进企业战略发展", title:"表面缺效率，实际不聚焦", icon:require("@/assets/images/icon3.png")},
      ],
      fourLeftList:[
        {value:"促进企业战略、目标的能够被有效承接，<br/>形成目标导向、愿意承诺的组织氛围", title:"", icon:"", class:"l1"}, //
        {value:"通过培训形成团队共识的组织工作效益<br/>提升沟通语言及行为模式", title:"", icon:"", class:"l1"}, //
        {value:"能形成良好的企业沟通文化，<br/>避免更多的不必要的组织内冲突产生", title:"", icon:"", class:"l2"},
      ],
      fourRightList:[
        {value:"用系统的思维和方法实现组织、<br/>员工和管理者自我的三方价值", title:"", icon:"", class:"r1"},
        {value:"自我诊断在目标、流程及关系三个<br/>管理要素上的不足，并发展出应采取<br/>的行动策略", title:"", icon:"", class:"r2"},
        {value:"通过对需求的系统性分析，<br/>提出让多方满意的主张和解决方案", title:"", icon:"", class:"r3"},
        {value:"用有效的工具获得员工承诺、<br/>提升员工能力、提升员工动力", title:"", icon:"", class:"r4"},
      ],
      fiveTwoList:[
        [
          {name:"白永亮",info:"员工关系管理专家<br/>人力资源社会保障理事会常务副理事长", img:require("@/assets/images/byl.png")},
          {name:"邱庆剑",info:"知名人力资源管理专家<br/>财税专家<br />注册咨询师", img:require("@/assets/images/qqj.png")},
          {name:"程高纯",info:"人力资源规划专家<br/>PDP教练认证讲师<br />结构性思维认证讲师", img:require("@/assets/images/cgc.png")},
          {name:"刘飞",info:"薪酬绩效管理专家<br />人力资源管理学士<br />高级人力资源管理师", img:require("@/assets/images/lfe.png")},
          {name:"刘方",info:"招聘与培训管理专家<br />高级企业培训师<br/>国家职业规划师", img:require("@/assets/images/lfa.png")},
        ],
        // [
        //   {name:"刘毛华",info:"项目管理资深专家/剑桥大学<br/>国际培训师", img:require("@/assets/images/user.png")},
        //   {name:"张宝雷",info:"前华为高管,实战型研发管理<br/>培训专家", img:require("@/assets/images/user.png")},
        //   {name:"吕春兰",info:"实战谈判专家<br/>复旦EMBA最接地气的交谈专家", img:require("@/assets/images/user.png")},
        //   {name:"杨京川",info:"原IBM资深销售管理专家", img:require("@/assets/images/user.png")},
        //   {name:"杨奕",info:"原coach/时代华纳/<br/>摩根士丹利", img:require("@/assets/images/user.png")},
        // ],
      ],
      sixBoxList:[
        {value:"十余年历史沉淀，成就业界知名品牌。任外界风云变幻，始终坚守劳务领域研究与实践。",name:'时间考验',icon:'el-icon-user'},
        {value:"专注于劳务行业研究与实践。为中小企业主解决最关键最困惑的问题，让中小企业主专注于其自身擅长的业务管理。",name:"内容领域", icon:"el-icon-document"},
        {value:"在顶层设计、人才激励、节税工程方面我面搭建了国内一流的专家团队",name:"智囊团队", icon:"el-icon-search"},
        {value:"系统性地解决中小企业主的关键问题，让中小企业主彻底解放，持久解放，免于头痛医头，脚痛医脚",name:"系统优势", icon:"el-icon-reading"},
        {value:"服务网点遍布全国",name:"网点服务", icon:"el-icon-trophy"},
        {value:"在多行业，多领域资源对接，在基础建设的优势上为中小企业对接资源。",name:"生态对接", icon:"el-icon-edit-outline"},
      ],
      swiperHeight: '',
      clientWidth: 0,
    }
  },
  computed: {
    isMobile() {
      return !(this.clientWidth > 900)
    }
  },
  methods: {
    // 获取轮播列表
    getSwipers () {
      this.$store.dispatch('getSwipers').then(res => {
        this.banner = res
        setTimeout(() => {
          this.swiperHeight = document
            .querySelectorAll('.swiper-item-img')[0]
            .height.toString()
        }, 500)
      })
    },
    
  },
  mounted () {
    // 获取轮播列表
    this.getSwipers()
    // 监听浏览器窗口的变化
    this.clientWidth =
      document.documentElement.clientWidth || document.body.clientWidth
      window.addEventListener('resize', () => {
      if (document.querySelectorAll('.swiper-item-img')[0]) {
        this.swiperHeight = document
          .querySelectorAll('.swiper-item-img')[0]
          .height.toString()
      }
      this.clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth
      // this.AdaptSwipers(this.clientWidth);
      })
  }
}
</script>

<style lang="less" scoped>
</style>
